div#main {
  /* -------------------------------------------------------------------------------------------------

  It's *strongly* suggested that you don't modify this file.  Instead, load a new stylesheet after
  this one in your layouts (eg formtastic_changes.css) and override the styles to suit your needs.
  This will allow you to update formtastic.css with new releases without clobbering your own changes.

  This stylesheet forms part of the Formtastic Rails Plugin
  (c) 2008 Justin French

  --------------------------------------------------------------------------------------------------*/


  /* NORMALIZE AND RESET - obviously inspired by Yahoo's reset.css, but scoped to just form.formtastic
  --------------------------------------------------------------------------------------------------*/
  form.formtastic, form.formtastic ul, form.formtastic ol, form.formtastic li, form.formtastic fieldset, form.formtastic legend, form.formtastic input, form.formtastic textarea, form.formtastic select, form.formtastic p { margin:0; padding:0; }
  form.formtastic fieldset { border:0; }
  form.formtastic em, form.formtastic strong { font-style:normal; font-weight:normal; }
  form.formtastic ol, form.formtastic ul, form.formtastic ol li, form.formtastic ul li { list-style:none; }
  form.formtastic abbr, form.formtastic acronym { border:0; font-variant:normal; }
  form.formtastic input, form.formtastic textarea, form.formtastic select { font-family:inherit; font-size:inherit; font-weight:inherit; }
  form.formtastic input, form.formtastic textarea, form.formtastic select { font-size:100%; }
  form.formtastic legend { white-space:normal; color:#000; }


  /* SEMANTIC ERRORS
  --------------------------------------------------------------------------------------------------*/
  form.formtastic ul.errors { color:#cc0000; margin:0.5em 0 1.5em 25%; list-style:square; }
  form.formtastic ul.errors li { padding:0; border:none; display:list-item; }


  /* FIELDSETS & LISTS
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset { overflow:auto; } /* clearing contained floats */
  form.formtastic fieldset.inputs { }
  form.formtastic fieldset.buttons { padding-left:25%; }
  form.formtastic fieldset ol { }
  form.formtastic fieldset.buttons li { float:left; padding-right:0.5em; }

  /* INPUT LIs
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li { margin-bottom:1.5em; }
  form.formtastic fieldset > ol > li { overflow:auto; } /* clearing contained floats */

  form.formtastic fieldset > ol > li.required { }
  form.formtastic fieldset > ol > li.optional { }
  form.formtastic fieldset > ol > li.error { }


  /* LABELS
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li label { display:block; width:25%; float:left; padding-top:.2em; }
  form.formtastic fieldset > ol > li > li label { line-height:100%; padding-top:0; }
  form.formtastic fieldset > ol > li > li label input { line-height:100%; vertical-align:middle; margin-top:-0.1em;}


  /* NESTED FIELDSETS AND LEGENDS (radio, check boxes and date/time inputs use nested fieldsets)
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li fieldset { position:relative; }
  form.formtastic fieldset > ol > li fieldset legend { position:absolute; width:95%; padding-top:0.1em; left: 0px; }
  form.formtastic fieldset > ol > li fieldset legend span { position:absolute; }
  form.formtastic fieldset > ol > li fieldset legend.label label { position:absolute; }
  form.formtastic fieldset > ol > li fieldset ol { float:left; width:74%; margin:0; padding:0 0 0 25%; }
  form.formtastic fieldset > ol > li fieldset ol li { padding:0; border:0; }


  /* INLINE HINTS
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li p.inline-hints { color:#666; margin:0.5em 0 0 25%; }


  /* INLINE ERRORS
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li p.inline-errors { color:#cc0000; margin:0.5em 0 0 25%; }
  form.formtastic fieldset > ol > li ul.errors { color:#cc0000; margin:0.5em 0 0 25%; list-style:square; }
  form.formtastic fieldset > ol > li ul.errors li { padding:0; border:none; display:list-item; }


  /* STRING & NUMERIC OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.string input { max-width:74%; }
  form.formtastic fieldset > ol > li.password input { max-width: 13em; }
  form.formtastic fieldset > ol > li.numeric input { max-width:74%; }


  /* TEXTAREA OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.text textarea { width:74%; }


  /* HIDDEN OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset ol li.hidden { display:none; }

  /* BOOLEAN OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.boolean label { padding-left:25%; width:auto; }
  form.formtastic fieldset > ol > li.boolean label input { margin:0 0.5em 0 0.2em; }


  /* RADIO OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.radio { }
  form.formtastic fieldset > ol > li.radio fieldset ol { margin-bottom:-0.6em; }
  form.formtastic fieldset > ol > li.radio fieldset ol li { margin:0.1em 0 0.5em 0; }
  form.formtastic fieldset > ol > li.radio fieldset ol li label { float:none; width:100%; }
  form.formtastic fieldset > ol > li.radio fieldset ol li label input { margin-right:0.2em; }


  /* CHECK BOXES (COLLECTION) OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.check_boxes { }
  form.formtastic fieldset > ol > li.check_boxes fieldset ol { margin-bottom:-0.6em; }
  form.formtastic fieldset > ol > li.check_boxes fieldset ol li { margin:0.1em 0 0.5em 0; }
  form.formtastic fieldset > ol > li.check_boxes fieldset ol li label { float:none; width:100%; }
  form.formtastic fieldset > ol > li.check_boxes fieldset ol li label input { margin-right:0.2em; }



  /* DATE & TIME OVERRIDES
  --------------------------------------------------------------------------------------------------*/
  form.formtastic fieldset > ol > li.date fieldset ol li,
  form.formtastic fieldset > ol > li.time fieldset ol li,
  form.formtastic fieldset > ol > li.datetime fieldset ol li { float:left; width:auto; margin:0 .3em 0 0; }

  form.formtastic fieldset > ol > li.date fieldset ol li label,
  form.formtastic fieldset > ol > li.time fieldset ol li label,
  form.formtastic fieldset > ol > li.datetime fieldset ol li label { display:none; }

  form.formtastic fieldset > ol > li.date fieldset ol li label input,
  form.formtastic fieldset > ol > li.time fieldset ol li label input,
  form.formtastic fieldset > ol > li.datetime fieldset ol li label input { display:inline; margin:0; padding:0;  }
}
