body {
  margin: 0 0 1em 0;
  font-size: 14px;
  line-height: 1.4286;
  color: #555;
  background: #fff;
  font-family: "Helvetica Neue", Arial, Verdana, sans-serif;
}

a {
  color: #3366cc;
  outline: none;
  text-decoration: underline;
}

a:visited {
  color: #666;
}

a:hover {
  color: #66cc33;
  text-decoration: none;
}

p, ul, blockquote, pre, td, th {
  margin: 1.4286em 0;
  font-size: 1em;
  line-height: 1.4286;
}

ul, ol {
  margin: 1.4286em 0;
  text-align: left;
}

li {
  line-height: 1.4286;
}

table {
  border-collapse: collapse;
  margin-bottom: 1.5em;
  width: 100%;
}

table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

table th {
  font-weight: bold;
}

table thead, table thead tr, table thead th {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #efefef;
}

table tr.log pre {
  margin: 0.7143em 1em;
  font-size: 0.8em;
  overflow-x: scroll;
  width: 558px;
}

table tr.log {
  background-color: #efefef;
  display: none;
}

div.log {
  font-size: 0.8em;
}

div.log pre {
  overflow-x: scroll;
}

tr.sphinx-error {
  background-color: #FFC8A6;
}

strong {
  font-family: Helvetica, Arial;
  color: #8e7a2b;
  font-weight: bold;
}

em {
  font-weight: bold;
  font-style: italic;
}

code {
  font-family: 'Bitstream Vera Sans Mono', 'Courier', monospace;
}

.wrapper {
  width: 960px;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  position: relative;
}

div#navigation {
  clear: both;
  margin: 0 0 0 0;
  padding: .8em 0 .6em 0;
  overflow: hidden;
  background: #efefef;
  color: #888;
  border-bottom: 1px solid #ccc;
}

div#header {
  clear: both;
  margin: 1em auto 0 auto;
  padding-top: 10px;
  text-align: center;
  overflow: hidden;
}

div#language {
  position: absolute;
  top: 0;
  right: 20px;
}

div#language ul {
  margin:0;
  padding:0;
}

div#language ul li {
  float: right;
  margin-right: 0px;
  text-align: left;
}

div#language ul li a,
div#language ul li a:visited {
  display: block;
  padding: 0em 0.75em 0.25em 0.75em ;
}

div#language ul li.selected a,
div#language ul li.selected a:visited {
  font-weight: bold;
}


div#navigation .wrapper {
  width: 940px;
}

div#navigation .wrapper a#logo {
  color: #828282;
  display:block;
  float: left;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.2em;
  margin: 0.15em 1em 0 0;
  padding: 0 1em 0 0;
  text-decoration: none;
}

div#navigation .wrapper a#logo span {
  color: #bbbaba;
  font-size: .85em;
}


div#navigation ul {
  margin:0;
  padding:0;
}

div#navigation ul li {
  margin: 0;
  padding: 0;
  float: left;
  border-left: 1px solid #ccc;
}

div#navigation ul li.lang {
  float: right;
  border-left: none;
  font-size: .83em;
}

div#navigation ul li a,
div#navigation ul li a:visited {
  color: #999;
  display:block;
  padding: 0em 1em 0 1em ;
  text-decoration:none;
}

div#navigation ul li a:hover,
div#navigation ul li a:visited {
  text-decoration:underline;
}

div#navigation ul li.selected a,
div#navigation ul li.selected a:visited {
  text-decoration: none;
  color: #6c3;
}

div#navigation ul li.active a,
div#navigation ul li.selected a:visited {
  text-decoration: none;
  color: #444;
}

div#content {
  clear: both;
  margin: 0 auto 3em auto;
  padding: 0 0 0;
  text-align:center;
  overflow: hidden;
  background: url('/images/bg-content.gif') repeat-y center top;
}

div#content div.pad {
  margin-left: 20px;
  margin-right: 20px;
}

div#main {
  float:left;
  width: 580px;
  overflow: hidden;
  padding-top: 2em;
}

#main ul,
#main ul li {
  list-style-type: disc;
}

#main ol,
#main ol li {
  list-style-type: decimal;
}

#main li {
  margin-left: 1.4286em;
}

div#sidebar {
  float:right;
  width: 325px;
  overflow: hidden;
  padding-top: 0;
}

#sidebar ul {
  margin:0 0 1.5em 0;
  padding:0;
}


#sidebar ul li a,
#sidebar ul li a:visited {
  display:block;
  text-decoration:none;
  padding:4px 0 3px 20px;
  border-top: 1px solid #efefef;
}

#sidebar ul li a:hover,
#sidebar ul li a:visited:hover {
  text-decoration:underline;
}

#sidebar ul li.selected a,
#sidebar ul li.selected a:visited {
  color: #6c3;
  background: #efefef;
  margin-left: 0;
  padding-left: 20px;
}

#sidebar ul li ul {
  border-bottom: 0;
  list-style-type: disc;
  margin-bottom: 0.5em;
}

#sidebar ul li ul li {
  border-bottom: 0;
  list-style-type: disc;
  margin-left: 1.5em;
}

#sidebar ul li ul li a,
#sidebar ul li ul li a:visited,
#sidebar ul li.selected ul li a,
#sidebar ul li.selected ul li a:visited {
  display:block;
  text-decoration:none;
  padding:0;
  border-top: 0;
  font-weight: normal;
  color: #195190;
}

#sidebar ul li ul li.selected a,
#sidebar ul li ul li.selected a:visited {
  color: #444;
  font-weight: normal;
}

div#footer {
  clear: both;
  margin: 0 auto;
  padding-top: 10px;
  text-align: center;
  overflow: hidden;
  background: #efefef;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd
}

div#footer .wrapper {
  background: transparent;
  width: 920px;
}

div#footer div.box {
  float: left;
  width: 280px;
  margin-right: 30px;
  color: #888;
}

div#footer div.last {
  margin-right: 0;
  margin-left: 15px;
}

div#footer div.box ul li a,
div#footer div.box ul li a:visited {
  color: #7d8997;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #40454F;
}

h1 {
  font-size: 3em;
  line-height: 1em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 1.6em;
  margin-bottom: 1em;
  line-height: 1em;
  letter-spacing: -0.5px;
}

h3 {
  font-size: 1.35em;
  line-height: 1em;
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.15em;
  line-height: 1.25;
  margin-bottom: 1.25em;
  height: 1.25em;
}

h6 {
  font-size: 0.95em;
  font-weight: normal;
}

h1 img, h2 img, h3 img, h4 img, h5 img, h6 img {
  margin: 0;
}

h1 a,
h1 a:visited,
h2 a,
h2 a:visited,
h3 a,
h3 a:visited,
h4 a,
h4 a:visited,
h5 a,
h5 a:visited {
  color: #464755;
}

dl {
  margin: 1em 0;
}

dt {
  float: left;
  clear: left;
  font-weight: bold;
}

dd {
  margin-left: 10em;
}

div#header h1 {
  float: left;
  width: 350px;
  text-indent: -20000em;
  background: transparent;
  font-size:1px;
  margin: 20px 0 0 20px;
}

div#header h1 a,
div#header h1 a:visited,
div#header h1 a:hover,
div#header h1 a:visited:hover {
  display: block;
  width: 350px;
  height: 75px;
}

div#header h1.riddle {
  float: none;
  width: 100%;
  text-indent: 0;
  background: #fff;
  color: #000;
  font-size:4em;
  margin: 0;
  padding:0;
  font-family: Impact;
  text-transform: uppercase;
  font-weight: normal;
  background: url('/images/heading.png') no-repeat top left;
}

div#header .riddle span {
  visibility: hidden;
}

div#header .riddle .flying {
  color: #20466F;
}

div#header .riddle .sphinx {
  color: #3A3A3B;
}

div#header h1.riddle a,
div#header h1.riddle a:visited,
div#header h1.riddle a:hover,
div#header h1.riddle a:visited:hover {
  display: block;
  width: 100%;
  height: auto;
  color: #000;
  text-decoration: none;
  padding: 10px 0;
}

div#header p {
  color: #777;
  display: block;
  font: italic 1.25em Georgia, Times, Serif;
  line-height: 1.67em;
  margin: 0.935em 0 1.87em 0;
  padding: 0 0 1.25em 0;
  border-bottom: 1px solid #ccc;
}

div#sidebar h2 {
  font-size: .9em;
  letter-spacing: .5px;
  text-transform:uppercase;
  margin: 40px 20px 5px 20px;
}

.highlight pre {
  font-family: 'Bitstream Vera Sans Mono', 'Courier', monospace;
  padding: 0 1.5em;
}

.highlight br {
  display: none;
}

.highlight {
  background-color: #f8f8f8;
  border: 1px solid silver;
  font-family: 'Courier New', 'Terminal', monospace;
  color: #100;
  margin: 1.5em 0;
}

div#content div.pad {
  margin-left: 0;
  margin-right: 0;
}
